<template>
  <v-app>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Вход</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="email"
                    v-model="email"
                ></v-text-field>
                <v-text-field
                    id="password"
                    prepend-icon="mdi-form-textbox-password"
                    name="password"
                    label="Пароль"
                    type="password"
                    v-model="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" link to="/signup">Регистрация</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="signIn">Вход</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    username: '',
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions("Auth", ["login"]),
    async signIn() {
      await this.login({
        email: this.email,
        password: this.password,
      });
      await this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>